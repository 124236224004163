import React from "react";
import { getAPIUrl } from "../config";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import {useContext} from 'react';
import UserContext from "../components/UserContext";
import "./Login.css";

const goLogin = async (data) => {
  let response = await fetch(getAPIUrl() + `/users/authenticate`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

const Login = () => {
  const navigate = useNavigate();
  let userContext = useContext(UserContext);
  const usernameInput = React.createRef();
  const passwordInput = React.createRef();
  const isAuthenticated = localStorage.getItem("access_token") !== null;
  const userType = JSON.parse(localStorage.getItem("user"));

  if (isAuthenticated) {
    if (userType && userType.role <= 2) {
      return <Navigate to="/home" />;
    } else {
      return <Navigate to="/site" />;
    }
  }
  const checkLogin = async () => {
    const data = {
      username: usernameInput.current.value,
      password: passwordInput.current.value,
    };
    let result = await goLogin(data);
    
    if (result.res) {
      userContext.doLogin(result);
     {result.user.role <= 2 ?  navigate("/home") : navigate("/site")}
    } else {
      alert(result.msg);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      checkLogin();
    }
  };
  
 
  return (
    <div className="loginBody">
    <section className="loginContainer">
    <div className="login-box">
        <div style={{
          display: "flex",
          justifyContent: "center"
        }}> <img src="/user.png" width="100px" alt="user icon" />
        </div>
        <h2 className="text-center p-3">Site Monitor</h2>
        <input ref={usernameInput} placeholder="username" className="form-control " name="" />
        <input ref={passwordInput} type="password" onKeyDown={handleKeyDown}
 placeholder="password" className="form-control mt-2" name=""/>
         <input
        type="button"
        className="btn btn-outline-primary btn-block form-control submitBtn"
        onClick={()=>checkLogin()}
        value="Login"
      />
    </div>
  </section>
  </div>
  );
}
export default Login;
