import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryIcon from "@mui/icons-material/History";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CircularProgress } from "@mui/material";

const dialogTitle = "Actions";
const deployTitle = "Deploy Latest Code";
const deployStagingTitle = "Deploy On Staging";
const deployProdTitle = "Deploy On Production";
const branchTitle = "Check Branch";
const logTitle = "Git Log";
const statusTitle = "Git Status";
const spaceTitle = "Used Space";

const DeployDialog = ({
    open,
    onClose,
    onCheckBranch,
    clickedButton,
    onGitLog,
    onDoDeploy,
    deployText,
    isLoading,
    onGitStatus,
    getSpace,
    selectedItemId }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            PaperProps={{
                style: {
                    width: "800px",
                    height: "550px",
                    position: "absolute",
                    top: "30px",
                },
            }}>
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {dialogTitle} ({selectedItemId?.domain})
                    <div style={{ marginLeft: '5rem' }}>
                        {spaceTitle} : {getSpace?.used ? `${getSpace?.used}/${getSpace?.total} GB` : "Not Configure"}
                    </div>
                </div> <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "8px", right: "15px" }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                    <div className="d-flex gap-2">
                        {selectedItemId?.domain.includes('cdnclient.remotexs.co') || selectedItemId?.domain.includes('cdnadmin.remotexs.co') 
                        ? 
                        <>
                        <Button
                            variant="contained"
                            disabled={isLoading ? true : false}
                            color={clickedButton === "doDeploy" ? "primary" : "secondary"}
                            style={{
                                borderRadius: "5",
                                margin: "8px 0",
                            }}
                            onClick={()=>onDoDeploy("deploy")}
                        >
                            <CloudUploadIcon sx={{ marginRight: "8px" }} /> {deployStagingTitle}
                        </Button>
                        <Button
                            variant="contained"
                            disabled={isLoading ? true : false}
                            color={clickedButton === "doDeploy" ? "primary" : "warning"}
                            style={{
                                borderRadius: "5",
                                margin: "8px 0",
                            }}
                            onClick={()=>onDoDeploy("productionDeploy")}
                        >
                            <CloudUploadIcon sx={{ marginRight: "8px" }} /> {deployProdTitle}
                        </Button>
                        </>
                        : 
                        <Button
                            variant="contained"
                            disabled={isLoading ? true : false}
                            color={clickedButton === "doDeploy" ? "primary" : "secondary"}
                            style={{
                                borderRadius: "5",
                                margin: "8px 0",
                            }}
                            onClick={()=>onDoDeploy("deploy")}
                        >
                            <CloudUploadIcon sx={{ marginRight: "8px" }} /> {deployTitle}
                        </Button>
                        }
                    </div>
                    <div className="d-flex gap-2">
                        <Button
                            variant="contained"
                            color={clickedButton === "checkBranch" ? "primary" : "success"}
                            style={{
                                borderRadius: "5",
                                margin: "8px 0",
                            }}
                            onClick={onCheckBranch}
                        >
                            <CheckCircleIcon sx={{ marginRight: "8px" }} /> {branchTitle}
                        </Button>
                        <Button
                            variant="contained"
                            color={clickedButton === "checkLog" ? "primary" : "success"}
                            style={{
                                borderRadius: "5",
                                margin: "8px 0",
                            }}
                            onClick={onGitLog}
                        >
                            <HistoryIcon sx={{ marginRight: "8px" }} /> {logTitle}
                        </Button>
                        <Button
                            variant="contained"
                            color={clickedButton === "checkStatus" ? "primary" : "success"}
                            style={{
                                borderRadius: "5",
                                margin: "8px 0",
                            }}
                            onClick={onGitStatus}
                        >
                            <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} /> {statusTitle}
                        </Button>
                    </div>
                </div>
                <div >
                    {isLoading ? (
                        <div style={{ textAlign: "center", margin: "20px" }}>
                            <p>Please hold on for a moment, it will take some time.</p>
                            <CircularProgress />
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                            <b>{deployText.titel}</b>
                            <div dangerouslySetInnerHTML={{ __html: deployText?.text }} />
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
export default DeployDialog;