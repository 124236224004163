import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSite,
  getDeploySiteById,
  getGitBranchById,
  getGitLogById,
  getGitStatusById,
  getSiteByUserId,
  getSiteData,
  getSpaceById,
  updateSiteData,
} from "../services/siteAPI";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import moment from "moment";
import { title } from "../config";
import { siteActiveInactive } from "../services/constant";
import { getUserDetails } from "../services/userStorage";
import DeployDialog from "../components/DeployDialog";
import { addLogData } from "../services/logApi";


export function Site() {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState("");

  const userDetails = getUserDetails();

  useEffect(() => {
    reloadSite();
  }, []);

  const reloadSite = async () => {
    if (userDetails.role > 2) {
      const records = await getSiteByUserId(userDetails?._id);
      setItems(records);
    } else {
      const records = await getSiteData();
      setItems(records);
    }
  };
  const doDelete = async (data) => {
    await deleteSite(data);
    reloadSite();
  };

  const doUpgrade = (link) => {
    const upgradeUrl = `https://core.${link}/check-update`;
    return upgradeUrl;
  };
  const doHistory = (id) => {
    navigate(`/state/${id}`);
  };
  const doEdit = (id) => {
    navigate(`/site/edit/${id}`);
  };
  const getLog = (id) => {
    navigate(`/site/log/${id}`);
  };

  const onSearchChange = (e) => {
    if (e?.target?.value?.length) {
      const text = e.target.value.toLowerCase();
      const searchResult = items?.length > 0 && items?.filter((record) => {
        if (record.name.toLowerCase().startsWith(text)) {
          return true;
        }
        if (record.domain.toLowerCase().startsWith(text)) {
          return true;
        }
      });
      setSearchText(e.target.value);
      setItems(searchResult);
    } else {
      setSearchText("");
      reloadSite();
    }
  };
  return (
    <div>
      <h3>{title} | Sites</h3>
      <AppMenu title="Sites" />
      <input
        type="search"
        value={searchText}
        onChange={onSearchChange}
        placeholder="Type Search Text"
      />
      {userDetails.role === 1 && (
        <span className="headerMargin">{getAddButton(navigate)}</span>
      )}
      <span style={{ float: "right", marginRight: "10px" }}>
        <b>Total:{items.length} &nbsp;&nbsp;</b>
      </span>
      {items.length > 0 && (
        <SiteList
          items={items}
          doDelete={doDelete}
          doEdit={doEdit}
          doHistory={doHistory}
          doUpgrade={doUpgrade}
          getLog={getLog}
          reloadSite={reloadSite}
          userDetails={userDetails}
        />
      )}
    </div>
  );
}

const getStatus = (status) => {
  let statusSite = siteActiveInactive.find(
    (item) => item.value === Number(status)
  );
  if (statusSite) {
    return statusSite.name;
  } else {
    return "";
  }
};

const getAddButton = (navigate) => {
  return (
    <button
      className="fa-sharp fa-plus btn btn-primary"
      onClick={() => {
        navigate("/site/add");
      }}
      variant="contained"
    >
      {" "}
      Add
    </button>
  );
};
function SiteList({
  items,
  doDelete,
  reloadSite,
  doEdit,
  getLog,
  doHistory,
  userDetails,
}) {
  const [localItems, setlocalItems] = useState(items);
  useEffect(() => {
    setlocalItems(items);
  }, [items]);

  const [toggleSorting, setToggleSorting] = useState(0);
  const [isDeployDialogOpen, setDeployDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [deployText, setDeployText] = useState({ title: "", text: "" });
  const [clickedButton, setClickedButton] = useState(null);
  const [spaceText, setSpaceText] = useState({ free: "", total: "" });
  const [isLoading, setIsLoading] = useState(false);

  let itemId = 0;
  const handleDeleteForSetItem = (item) => {
    itemId = item;
  };

  const sortTable = (field) => {
    let sortedData = [];
    if (toggleSorting === 1) {
      sortedData = [...localItems].sort((a, b) => {
        if (a[field] > b[field]) {
          return -1;
        } else if (a[field] < b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      setToggleSorting(0);
    } else {
      sortedData = [...localItems].sort((a, b) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      setToggleSorting(1);
    }
    setlocalItems(sortedData);
  };

  const handleOpenDialog = (item) => {
    setSelectedItemId(item);
    setDeployDialogOpen(true);
    getSpace(item._id);
  };

  const handleCloseDialog = () => {
    setDeployDialogOpen(false);
    setDeployText("");
    setSpaceText({ free: "", total: "" });
    setClickedButton(null);
  };

  const handleDeploy = async (deploy) => {
    setIsLoading(true);
    setClickedButton("doDeploy");
    const deployText = await getDeploySiteById(selectedItemId?._id, deploy);
    if (deployText) {
      const data = {
        siteId: selectedItemId?._id,
        domain: selectedItemId?.domain,
        deployAt: new Date(),
        siteName: selectedItemId?.name,
        userId: userDetails?._id,
        userName: userDetails?.name,
      };
      await addLogData(data);
      const newItem = {
        ...selectedItemId,
        notetime: new Date(),
        depDate: new Date(),
        username: userDetails?.name,
      };
      await updateSiteData(newItem);
      setDeployText({ titel: "Deployement Status", text: deployText?.msg });
      setIsLoading(false);
    }
    reloadSite();
  };
  const onGitStatus = async () => {
    setClickedButton("checkStatus");
    setIsLoading(true);
    const gitstatus = "gitstatus";
    const statusText = await getGitStatusById(selectedItemId?._id, gitstatus);
    if (statusText) {
      setDeployText({ titel: "Git Status", text: statusText?.msg });
      setIsLoading(false);
    }
  };
  const onCheckBranch = async () => {
    setClickedButton("checkBranch");
    setIsLoading(true);
    const gitbranch = "gitbranch";
    const branchText = await getGitBranchById(selectedItemId?._id, gitbranch);
    if (branchText) {
      setDeployText({ titel: "Current Branch", text: branchText?.msg });
      setIsLoading(false);
    }
  };
  const onGitLog = async () => {
    setClickedButton("checkLog");
    setIsLoading(true);
    const gitlog = "gitlog";
    const logText = await getGitLogById(selectedItemId?._id, gitlog);
    if (logText) {
      setDeployText({ titel: "Git log", text: logText?.msg });
      setIsLoading(false);
    }
  };
  const getSpace = async (item) => {
    const space = "space";
    const text = await getSpaceById(item, space);
    if (text) {
      const percentageFull = text?.size - text?.free;
      const sizeInGB = Math.floor(text?.size / 1073741824);
      const roundValue = Math.floor(percentageFull / 1073741824);
      setSpaceText({ used: roundValue, total: sizeInGB });
    }
  };

  const handleDelete = async() => {
    doDelete(itemId);
   
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target={'#exampleModal'}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Delete site
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure want to delete this site?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleDelete}
                className="btn btn-primary"
                data-bs-dismiss='modal'
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <Table className="table table-hover" responsive hover>
        <thead>
          <tr>
            <th onClick={() => sortTable("name")}>Name</th>
            <th>Status</th>
            <th>Last deployed</th>
            <th>Deployed By</th>
            <th onClick={() => sortTable("domain")}>Domain</th>
            <th>Active</th>
            {userDetails.role === 1 && <th>Edit</th>}
            <th>Deploy</th>
            {<th>Logs</th>}
            {userDetails.role === 1 && <th>History</th>}
            {userDetails.role === 1 && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {localItems.map((item) => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td>{item.cms}</td>
              <td>
                {item.depDate
                  ? moment(item.depDate).format("hh:mm A - DD/MM/YYYY")
                  : "-"}
              </td>
              <td>{item.userName ? `(${item.userName})` : "-"}</td>
              <td>
                <a href={`https://${item.domain}`} target="_blank">
                  {item.domain}
                </a>
              </td>
              <td>{getStatus(item.active)}</td>

              {userDetails.role === 1 && (
                <td>
                  <i
                    className="fas fa-edit btn btn-primary m-2"
                    onClick={() => doEdit(item._id)}
                    aria-hidden="true"
                  ></i>
                </td>
              )}
              <td>
                <i
                  className="fa-solid fa-cloud-arrow-up btn btn-dark m-2"
                  onClick={() => handleOpenDialog(item)}
                  aria-hidden="true"
                ></i>
              </td>
              <td>
                <i
                  className="fas fa-eye btn btn-success m-2 ml-2"
                  onClick={() => getLog(item._id)}
                  aria-hidden="true"
                ></i>
              </td>
              {userDetails.role === 1 && (
                <td>
                  <i
                    className="fa-solid fa-clock-rotate-left btn btn-warning m-2"
                    onClick={() => doHistory(item._id)}
                  ></i>
                </td>
              )}
              {userDetails.role === 1 && (
                <td>
                  <i
                    className="fa-solid fa-trash-can btn btn-danger m-2"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() => handleDeleteForSetItem(item._id)}
                    aria-hidden="true"
                  ></i>
                </td>
              )}
            </tr>
          ))
          }
        </tbody>
      </Table>
      <DeployDialog
        selectedItemId={selectedItemId}
        open={isDeployDialogOpen}
        onClose={handleCloseDialog}
        onDoDeploy={handleDeploy}
        onGitLog={onGitLog}
        onCheckBranch={onCheckBranch}
        deployText={deployText}
        onGitStatus={onGitStatus}
        clickedButton={clickedButton}
        isLoading={isLoading}
        getSpace={spaceText}
      />
    </>
  );
}
