export const getAPIUrl = () => {
  if (window.location.host.startsWith("localhost")) {
    // return 'https://api-monitor.pyther.com';
    return 'http://127.0.0.1:4000';
  } else if (window.location.host.startsWith("monitor.007percent.com")) {
    return 'https://api-monitor.007percent.com';
  } else if (window.location.host.startsWith("monitor.pyther.com")) {
    return 'https://api-monitor.pyther.com';
  } else {
    return 'http://127.0.0.1:4000';
  }
};
export const title = "Site Monitor";

